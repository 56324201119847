import styled from "styled-components";
import Fonts from "../../../../../assets/styles/Fonts";
import Colors from "../../../../../assets/styles/Colors";
import { tablet } from "../../../../../assets/styles/Responsive";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 55px;
  font-size: 18px;
  line-height: 20px;

  @media (max-width: ${tablet}) {
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 50px;
  }
`;

const Title = styled.div`
  padding-right: 45px;
  color: ${Colors.green};
  font-family: ${Fonts.IBMMedium};
`;

const Socials = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${tablet}) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

const SocialItem = styled.a`
  display: inline-block;
  font-family: ${Fonts.IBMSemiBold};
  text-decoration: underline;
  margin-left: 35px;
  color: ${Colors.green};

  &:first-child {
    margin-left: 0;
  }

  @media (max-width: ${tablet}) {
    margin-left: 0;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }

  &:hover {
    text-decoration: none;
  }
`;

export {
  Wrapper,
  Title,
  Socials,
  SocialItem,
};