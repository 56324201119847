import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Layout
import MainLayout from "../../layouts/MainLayout";
// Components
import Seo from "../Seo/seo";
import Share from './components/Share';
import LinkBlock from './components/LinkBlock';
// Utils
import publishDate from '../../../utils/publishDate';
// Style
import {
  Wrapper,
  ArticleBanner,
  BannerMask,
  BannerContent,
  PublishDate,
  Head1,
  ContentWrap,
  Content,
} from './styled';
import VisitUsToday from './components/VisitUsToday';

//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const Article = ({
  pageContext: {
    data: {
      share,
      article: {
        created_at,
        title,
        banner,
        content,
        articleLinkBlock,
      },
    }
  }
}) => {
  const [contentString, setContentString] = useState('');

  useEffect(() => {
    setContentString(content.replaceAll('/uploads', `${process.env.GATSBY_API_URL}/uploads`))
  }, [content, setContentString]);

  const image = getImage(banner?.localFile?.childImageSharp?.gatsbyImageData);
  const bgImage = convertToBgImage(image);

  return (
    <MainLayout>
      <Seo
        title={title}
      />
      <Wrapper>
        <ArticleBanner
          Tag="section"
          alt={title}
          {...bgImage}
        >
          <BannerMask />
          <BannerContent>
            <PublishDate>{publishDate(created_at)}</PublishDate>
            <Head1 value={title} />
          </BannerContent>
        </ArticleBanner>
        <ContentWrap>
          <Content>
              {contentString}
          </Content>
          <Share data={share} />
          <VisitUsToday/>
          {/* {articleLinkBlock ? <LinkBlock data={articleLinkBlock} /> : null} */}
        </ContentWrap>
      </Wrapper>
    </MainLayout>
  );
};

Article.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default Article;
