import React from 'react';
import PropTypes from 'prop-types';
// Style
import {
  Wrapper,
  Title,
  Socials,
  SocialItem,
} from './styled';

const Share = ({ data: {
  title,
  shareItems,
} }) => {

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Socials>
        {shareItems.map((item) => {
          const { name, link, id } = item;
          return <SocialItem href={link} key={id}>{name}</SocialItem>
        })}
      </Socials>
    </Wrapper>
  );
};

Share.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Share;
