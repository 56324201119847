import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// Fonts
import Fonts from '../../../../assets/styles/Fonts';
import { tablet } from "../../../../assets/styles/Responsive";

const Element = styled.h2`
  font-size: 36px;
  line-height: 40px;
  text-transform: uppercase;
  font-family: ${Fonts.DrukBold};

  @media (max-width: ${tablet}) {
    font-size: 30px;
    line-height: 33px;
  }
`;

const H2 = ({
  value, className
}) => (
  <Element className={className}>
    {value}
  </Element>
);

H2.defaultProps = {
  value: '',
};

H2.propTypes = {
  value: PropTypes.string,
};

export default H2;
