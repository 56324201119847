import { Link } from 'gatsby';
// UI
import H2 from '../../../../UI/Titles/H2';
// Styled
import styled from 'styled-components';
import Colors from '../../../../../assets/styles/Colors';
import Fonts from '../../../../../assets/styles/Fonts';
import { tablet } from "../../../../../assets/styles/Responsive";

const Wrap = styled.div`
  margin-top: 55px;

  @media (max-width: ${tablet}) {
    margin-top: 75px;
  }
`;

const Content = styled(Link)`
  display: block;
  padding: 30px 30px 85px;
  text-decoration: none;
  color: ${Colors.shadowGreen};
  
  background-color: ${Colors.green};

  @media (max-width: ${tablet}) {
    padding-bottom: 80px;
  }
`;

const Head2 = styled(H2)`
  @media (max-width: ${tablet}) {
    font-size: 25px;
    line-height: 28px;
  }
`;

const Text = styled.div`
  margin-top: 20px;
  font-size: 24px;
  line-height: 26.4px;
  font-family: ${Fonts.IBMSemiBold};

  @media (max-width: ${tablet}) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export {
  Wrap,
  Content,
  Head2,
  Text,
}