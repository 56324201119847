import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled.div`
    width: 100%;

    margin-top: 5vh;
    padding: 3vh 5vh;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    color: var(--green);
    background-color: var(--darkgreen);

    font-family: Druk Wide Bold;

    h3{
        font-family: IBM Plex Mono Bold;
        margin-top: 1vh;
        margin-bottom: 5vh;
    }
`;

export default function VisitUsToday()
{
    return(
        <Wrapper>
            <h1>
                VISIT US TODAY!
            </h1>

            <Link
                to={'/near-me'}
            >
                <h3>
                    Dispensary near me →
                </h3>
            </Link>
        </Wrapper>
    )
}