import BackgroundImage from 'gatsby-background-image';
import ReactMarkdown from 'react-markdown';
// UI
import H1 from '../../UI/Titles/H1';
// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';
import Typography from '../../../assets/styles/Typography';
import Fonts from '../../../assets/styles/Fonts';
import { tablet } from "../../../assets/styles/Responsive";

const Wrapper = styled.div`
  padding: 120px 0 75px;
  background-color: ${Colors.gin};

  @media (max-width: ${tablet}) {
    padding-top: 80px;
  }
`;

const ArticleBanner = styled(BackgroundImage)`
  min-height: 570px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 0;

  @media (max-width: ${tablet}) {
    min-height: 420px;
  }
`;

const BannerMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: linear-gradient(223.93deg, #446961 -15.98%, rgba(68, 105, 97, 0) 137.72%);
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.aqua};
  z-index: 99;
`;

const PublishDate = styled.div`
  font-size: 18px;
  line-height: 20px;

  @media (max-width: ${tablet}) {
    font-size: 16px;
  }
`;

const Head1 = styled(H1)`
  text-align: center;
  margin: 50px 0 0;
  max-width: 960px;
  font-family: ${Fonts.DrukMedium};

  @media (max-width: ${tablet}) {
    margin-top: 15px;
  }
`;

const ContentWrap = styled.div`
  max-width: 925px;
  padding: 0 20px;
  margin: 0 auto;
`;

const Content = styled(ReactMarkdown)`
  ${Typography.richText};
  margin-top: 80px;
  color: ${Colors.green};

  *:first-child {
    margin-top: 0 !important;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 80px;
    & + * {
      margin-top: 35px;
    }
  }

  img {
    display: block;
    margin-bottom: 35px;
  }

  h1 {
    font-size: 48px;
    line-height: 62px;
    text-transform: uppercase;
    font-family: ${Fonts.DrukBold};
  }

  h2 {
    font-size: 36px;
    line-height: 42px;
    font-family: ${Fonts.DrukBold};
  }

  h3 {
    font-size: 24px;
    line-height: 31.2px;
    font-family: ${Fonts.IBMSemiBold};
  }

  @media (max-width: ${tablet}) {
    margin-top: 40px;

    h1, h2, h3, h4, h5, h6 {
      margin-top: 65px;
      & + * {
        margin-top: 20px;
      }
    }

    img {
      margin-bottom: 25px;
    }

    h1 {
      font-size: 24px;
      line-height: 40px;
    }

    h2 {
      font-size: 22px;
      line-height: 33px;
    }

    h3 {
      font-size: 18px;
      line-height: 21px;
    }
  }
`;

export {
  Wrapper,
  ArticleBanner,
  BannerMask,
  BannerContent,
  PublishDate,
  Head1,
  ContentWrap,
  Content,
}